<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The following two-step process has a
        <number-value :value="pctYield" unit="\%" />
        yield for each step.
      </p>
      <p class="mb-2 pl-6">
        <chemical-latex content="CH4 + 4 Cl2 -> CCl4 + 4 HCl" />
      </p>
      <p class="mb-4 pl-6">
        <chemical-latex content="CCl4 + 2 HF -> CCl2F2 + 2 HCl" />
      </p>

      <p class="mb-3">
        The
        <chemical-latex content="CCl4" />
        that is formed in the first step of the process is used as the reactant for the second step.
        Assuming
        <number-value :value="molCH4" unit="\text{mol}" />
        of
        <chemical-latex content="CH4" />
        are used in the reaction with excess amounts of both
        <chemical-latex content="Cl2" />
        and
        <chemical-latex content="HF," />
        what is the <span class="text-bold">total</span> amount, in moles, of
        <chemical-latex content="HCl" />
        that would be formed?
      </p>

      <calculation-input
        v-model="inputs.molesHCl"
        prepend-text="$\text{HCl:}$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'Question71',
  components: {
    ChemicalLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        molesHCl: null,
      },
    };
  },
  computed: {
    pctYield() {
      return this.taskState.getValueBySymbol('pctYield').content;
    },
    molCH4() {
      return this.taskState.getValueBySymbol('molCH4').content;
    },
  },
};
</script>
